import {Map, View} from 'ol';
import OSM from 'ol/source/OSM';
import Vector from 'ol/source/Vector';
import BingMaps from 'ol/source/BingMaps';
import TileWMS from 'ol/source/TileWMS';

import GeoJSON from 'ol/format/GeoJSON';
import WFS from 'ol/format/WFS';
import Bbox from 'ol/format/filter/Bbox';

import {and, like, or} from 'ol/format/filter';

import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';

import {Group as LayerGroup, Tile as TileLayer} from 'ol/layer';
import * as olProj from 'ol/proj';
import { register } from "ol/proj/proj4";
import { get as getProjection, transformExtent } from "ol/proj";
import proj4 from "proj4";

import {defaults} from 'ol/control';
import MousePosition from 'ol/control/MousePosition';
import ZoomToExtent from 'ol/control/ZoomToExtent';

import {ScaleLine, defaults as defaultControls} from 'ol/control.js';
import {createStringXY} from 'ol/coordinate';
import {getCenter} from 'ol/extent';

import { Tab } from "bootstrap/js/dist/tab";
import VectorLayer from 'ol/layer/Vector';

var delay = (function(){
  var timer = 0;
  return function(callback, ms){
    clearTimeout (timer);
    timer = setTimeout(callback, ms);
  };
})();

//Define and reproject to EPSG:32635
proj4.defs(
  "EPSG:32635",
  "+proj=utm +zone=35 +ellps=WGS84 +datum=WGS84 +units=m +no_defs"
);
register(proj4);

const proj32635 = getProjection("EPSG:32635");
proj32635.setExtent([166021.44, 0.00, 584994.66, 9329005.18]);
var bounds = [574500.4591,4784231.8619,
  574500.5591,4784231.9619];

const layers = {};

let format = 'image/png';

layers["osm"] = new TileLayer({
  source: new OSM(),
  name: 'osm',
  visible: true
});

layers["osmsat"] = new TileLayer({
  preload: Infinity,
  source: new BingMaps({
    key: 'ApOScIyoy5snT55MKP2anLg08A9tPv_tEIATyUcpiTuiFQLvT16dxQA-39BvgZWE',
    imagerySet: 'AerialWithLabelsOnDemand',
  }),
  name: 'osmsat',
  visible: false
});

layers["GazoprovodnamrejaVarna"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:GazoprovodnamrejaVarna",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Газопроводна мрежа',
  serverName: 'GazoprovodnamrejaVarna',
  visible: false
});

// Vik Layers
layers["Vodoprovod_1"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Vodoprovod_1",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Водопровод',
  serverName: 'Vodoprovod_1',
  visible: false
});

layers["Kanalizaciq_1"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Kanalizaciq_1",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Канализация',
  serverName: 'Kanalizaciq_1',
  visible: false
});

var layerGroupVik = new LayerGroup({
  layers: [layers["Kanalizaciq_1"],layers["Vodoprovod_1"]],
  name: 'ВиК'
});

var layerGroupInj = new LayerGroup({
  layers: [layerGroupVik,layers["GazoprovodnamrejaVarna"]],
  name: 'ВиК'
});

// Granici Layers
layers["GranicaObshtinaVarna"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:GranicaObshtinaVarna",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Граница на община',
  serverName: 'GranicaObshtinaVarna',
  visible: false
});

layers["Nova_str_granica"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Nova_str_granica",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Нова строителнна граница',
  serverName: 'Nova_str_granica',
  visible: false
});
layers["Stara_str_granica"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Stara_str_granica",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Съществуваща строителнна граница',
  serverName: 'Stara_str_granica',
  visible: false
});

var layerGrouStroitelni = new LayerGroup({
  layers: [layers["Nova_str_granica"],layers["Stara_str_granica"]],
  name: 'Строителни граници'
});

layers["Zemlishta"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Zemlishta",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Землищни граници',
  serverName: 'Zemlishta',
  visible: false
});

layers["ProtAreas"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:ProtAreas",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Защитени местности',
  serverName: 'ProtAreas',
  visible: false
});

layers["ZonaA"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:ZonaA",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Зона A от ЗУЧК',
  serverName: 'ZonaA',
  visible: false
});

layers["ZonaB"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:ZonaB",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Зона Б от ЗУЧК',
  serverName: 'ZonaB',
  visible: false
});

layers["ProtAreasHabitats"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:ProtAreasHabitats",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Натура хабитати',
  serverName: 'ProtAreasHabitats',
  visible: false
});

layers["ProtAreasBirds"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:ProtAreasBirds",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Натура птици',
  serverName: 'ProtAreasBirds',
  visible: false
});

var layerGroupZashtiteni = new LayerGroup({
  layers: [
            layers["ProtAreasBirds"],
            layers["ProtAreasHabitats"],
            layers["ZonaB"],
            layers["ZonaA"],
            layers["ProtAreas"]
          ],
  name: 'Граници на защитени зони и натура'
});

var layerGroupGranici = new LayerGroup({
  layers: [layerGroupZashtiteni,layers["Zemlishta"],layerGrouStroitelni,layers["GranicaObshtinaVarna"],layerGroupAdmin],
  name: 'Граници'
});

layers["District_boundries"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:District_boundries",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Административни райони',
  serverName: 'District_boundries',
  visible: false
});

layers["Mikroraioni"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Mikroraioni",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Микрорайони и селищни образувания',
  serverName: 'Mikroraioni',
  visible: false
});

var layerGroupAdmin = new LayerGroup({
  layers: [layers["District_boundries"],layers["Mikroraioni"]],
  name: 'Административно деление'
});

layers["Osevi_tochki_Varna_2"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Osevi_tochki_Varna_2",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Осеви точки',
  serverName: 'Osevi_tochki_Varna_2',
  visible: false
});

layers["Os21"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Os21",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Ос',
  serverName: 'Os21',
  visible: false
});

layers["Bordiuri_park2"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Bordiuri_park2",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Бордюри и паркинги',
  serverName: 'Bordiuri_park2',
  visible: false
});

layers["Ulichna_regulacia2"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Ulichna_regulacia2",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Улична регулация',
  serverName: 'Ulichna_regulacia2',
  visible: false
});

layers["Dvorishtna_regulacia2"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Dvorishtna_regulacia2",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Дворищна регулация',
  serverName: 'Dvorishtna_regulacia2',
  visible: false
});

layers["PUP"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:PUP",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'ПУП',
  serverName: 'PUP',
  visible: false
});

var layerGroupRegulacii = new LayerGroup({
  layers: [
            layers["PUP"],
            layers["Dvorishtna_regulacia2"],
            layers["Ulichna_regulacia2"],
            layers["Bordiuri_park2"],
            layers["Os21"],
            layers["Osevi_tochki_Varna_2"]
          ],
  name: 'Регулации и ПУП и ПУР'
});

layers["Koti"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Koti",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Коти',
  serverName: 'Koti',
  visible: false
});

layers["Lake_depth"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Lake_depth",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Коти езеро',
  serverName: 'Lake_depth',
  visible: false
});

layers["relef_simple"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:relef_simple",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Хоризонтали',
  serverName: 'relef_simple',
  visible: false
});

var layerGroupRelef = new LayerGroup({
  layers: [
            layers["relef_simple"],
            layers["Lake_depth"],
            layers["Koti"]
          ],
  name: 'Релеф'
});

layers["Koti_Point"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Koti_Point",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'коти',
  serverName: 'Koti_Point',
  visible: false
});

layers["arheologia1"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:arheologia1",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'археология',
  serverName: 'arheologia1',
  visible: false
});

layers["Nekropoli_Polygon"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Nekropoli_Polygon",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'некропол',
  serverName: 'Nekropoli_Polygon',
  visible: false
});

layers["Sreda_Polygon"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Sreda_Polygon",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'среда',
  serverName: 'Sreda_Polygon',
  visible: false
});

layers["Geodesy_map"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Geodesy_map",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'територия с археологически находки от обобщена карта  на РИМ',
  serverName: 'Geodesy_map',
  visible: false
});

layers["Zoni_Polygon"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Zoni_Polygon",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'охранителни зони',
  serverName: 'Zoni_Polygon',
  visible: false
});

layers["StenaRim_Polygon"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:StenaRim_Polygon",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'римска стена',
  serverName: 'StenaRim_Polygon',
  visible: false
});

layers["Arhitekturni_pametnitsi"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Arhitekturni_pametnitsi",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'архитектурни паметници',
  serverName: 'Arhitekturni_pametnitsi',
  visible: false
});

var layerGroupKIN = new LayerGroup({
  layers: [
            layers["Arhitekturni_pametnitsi"],
            layers["StenaRim_Polygon"],
            layers["Zoni_Polygon"],
            layers["Geodesy_map"],
            layers["Sreda_Polygon"],
            layers["Nekropoli_Polygon"],
            layers["arheologia1"],
            layers["Koti_Point"]
          ],
  name: 'КИН'
});

layers["SGRADI_1"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:SGRADI_1",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Сгради',
  serverName: 'SGRADI_1',
  visible: false
});

layers["Imoti"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Imoti",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Имоти',
  serverName: 'Imoti',
  visible: false
});

layers["SIGN_1"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:SIGN_1",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Геодезични точки',
  serverName: 'SIGN_1',
  visible: false
});

var layerGroupKadaster = new LayerGroup({
  layers: [
            layers["SIGN_1"],
            layers["Imoti"],
            layers["SGRADI_1"]
          ],
  name: 'Кадастър'
});

layers["Bordiuri"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Bordiuri",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Регулация ОУП',
  serverName: 'Bordiuri',
  visible: false
});

layers["Elektrichka"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Elektrichka",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Скоростен релсов транспорт',
  serverName: 'Elektrichka',
  visible: false
});

layers["Okonchatelen_proekt"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Okonchatelen_proekt",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'ОУП на гр. Варна',
  serverName: 'Okonchatelen_proekt',
  visible: false
});

var layerGroupOUP = new LayerGroup({
  layers: [
            layers["Okonchatelen_proekt"],
            layers["Elektrichka"],
            layers["Bordiuri"]
          ],
  name: 'ОУП'
});

layers["landslide"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:landslide",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Граница свлачища',
  serverName: 'landslide',
  visible: false
});

layers["Zoni_sys_str_zabrana"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:Zoni_sys_str_zabrana",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Зони със строителна забрана',
  serverName: 'Zoni_sys_str_zabrana',
  visible: false
});

layers["EngGeolReg"] = new TileLayer({
  source: new TileWMS({
    url: "https://data.varnamap.bg//geoserver/wms",
    crossOrigin: "anonymous",
    params: {
      LAYERS: "varnamap:EngGeolReg",
      TILED: true
    },
    projection: "EPSG:32635"
  }),
  name: 'Инженерногеол. райониране',
  serverName: 'EngGeolReg',
  visible: false
});

var layerGroupRaionirane = new LayerGroup({
  layers: [
            layers["EngGeolReg"],
            layers["Zoni_sys_str_zabrana"],
            layers["landslide"]
          ],
  name: 'Инженерногеол. райониране и забрани'
});

// let mousePositionControl = MousePosition({
//   className: 'custom-mouse-position',
//   //target: document.getElementById('location'),
//   coordinateFormat: createStringXY(3),
//   undefinedHTML: '&nbsp;'
// });
const mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(2),
  className: 'custom-mouse-position',
  target: document.getElementById('location'),
});

var zoomToOriginalExtent = new ZoomToExtent({
  label: 'О', tipLabel:'Начална позиция на картата', extent: bounds
});

//vector layer for displaying WFS responses from identification and search
let vectorSource = new Vector();
let vectorLayer = new VectorLayer({
  source: vectorSource,
  name: 'theVectorLayer',
  style: new Style({
    stroke: new Stroke({
      color:'rgba(82, 82, 122,3)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(133, 133, 173,0.3)',
    })
  })
});
//vector layer for displaying the selected object from right menu
let vectorSourceSelected = new Vector();
let vectorLayerSelected = new VectorLayer({
  source: vectorSourceSelected,
  name: 'theVectorLayerSelected',
  style: new Style({
    stroke: new Stroke({
      color:'rgba(51, 51, 77,2.5)',
      width: 2
    }),
    fill: new Fill({
      color: 'rgba(82, 82, 122,0.8)',
    })
  })
});

const scaleControl = new ScaleLine({
  units: 'metric',
  bar: true,
  steps: 4,
  text: true,
  minWidth: 140,
});

const map = new Map({
  controls: defaults({attribution: true}).extend([mousePositionControl, zoomToOriginalExtent,scaleControl]),
//  controls: defaults({attribution: true}).extend([mousePositionControl]),
  layers: [
    layers["osm"], 
    layers["osmsat"], 
    layerGroupRaionirane,
    layerGroupKIN,
    layerGroupRelef,
    layerGroupInj,
    layerGroupGranici,
    layerGroupKadaster,
    layerGroupRegulacii,
    layerGroupOUP,
    vectorLayer,
    vectorLayerSelected  
  ],
  target: "map",
  view: new View({
    projection: "EPSG:32635",
    center: [574500.4591,4784231.8619],
    zoom: 12,
    maxZoom: 18,
    minZoom: 10
  })
});
map.getLayerGroup().set('name', 'Слоеве');

// map.getView().on('change:resolution', function(evt) {
//   let resolution = evt.target.get('resolution');
//   let units = map.getView().getProjection().getUnits();
//   let dpi = 25.4 / 0.28;
//   let mpu = olProj.METERS_PER_UNIT[units];
//   let scale = resolution * mpu * 39.37 * dpi;
//   scale = Math.round(scale);
//   document.getElementById('scale').innerHTML = "1 : " + scale;
// });
// map.getView().fit(bounds, map.getSize());

//Table of Contents
/**
* Build a tree layer from the map layers with visible and opacity 
* options.
* 
* @param {type} layer
* @returns {String}
*/
function buildLayerTree(layer) {

  let elem;
  let name = layer.get('name') ? layer.get('name') : "Group";
  let visibility = layer.get('visible');
  let div = "";
  
  //a condition to hide the vector layer from the TOC
  if (name == 'theVectorLayer' || name == 'theVectorLayerSelected' ){
      elem = '';
      return elem;
  }

  //get TOC elements
  if (!layer.getLayers) {
      if (visibility !== true ){
          div = "<li data-layerid='" + name + "'>" +
              "<span><span>" + layer.get('name')  +
              "</span><i class='bi bi-toggle-off'></i> " + "</span>";
      }
      else {
          div = "<li data-layerid='" + name + "'>" +
              "<span><span>" + layer.get('name') + 
              "</span><i class='bi bi-toggle-on'></i> " + "</span>";
      }
  }
  else{
      div = "<li data-layerid='" + name + "'>" +
              "<span>" + layer.get('name') + 
              "<i class='glyphicon glyphicon-minus'></i> " + "</span>";
  }
  
  if (layer.getLayers) {
      let sublayersElem = ''; 
      let layers = layer.getLayers().getArray(),
              len = layers.length;

      for (let i = len - 1; i >= 0; i--) {
          sublayersElem += buildLayerTree(layers[i]);

      }
      elem = div + " <ul>" + sublayersElem + "</ul></li>";
  } else {
      elem = div + " </li>";
  }
  return elem;
}

/**
* Initialize the tree from the map layers
* @returns {undefined}
*/
function initializeTree() {
  
  let elem = buildLayerTree(map.getLayerGroup());
  $('#layertree').empty().append(elem);

  $('.tree li:has(ul)').addClass('parent_li').find(' > span').attr('title', 'Покажи подслоевете');
  $('.tree li.parent_li > span').on('click', function(e) {
      let children = $(this).parent('li.parent_li').find(' > ul > li');
      if (children.is(":visible")) {
          children.hide('fast');
          $(this).attr('title', 'Отвори').find(' > i').fadeIn(400).addClass('glyphicon-plus').removeClass('glyphicon-minus');
      } else {
          children.show('fast');
          $(this).attr('title', 'Затвори').find(' > i').fadeIn(400).addClass('glyphicon-minus').removeClass('glyphicon-plus');
      }
      e.stopPropagation();
  });
}

/**
* Finds recursively the layer with the specified key and value.
* @param {ol.layer.Base} layer
* @param {String} key
* @param {any} value
* @returns {ol.layer.Base}
*/
function findBy(layer, key, value) {

  if (layer.get(key) === value) {
      return layer;
  }

  // Find recursively if it is a group
  if (layer.getLayers) {
      let layers = layer.getLayers().getArray(),
              len = layers.length, result;
      for (let i = 0; i < len; i++) {
          result = findBy(layers[i], key, value);
          if (result) {
              return result;
          }
      }
  }

  return null;
}

//Legend 
/**
* Build a <img> list from the map layers
* 
* @param {type} layer
* @returns {String}
*/
function buildLegend(layer) {

  let elem;
  //let name = layer.get('name') ? layer.get('name') : "Group";
  //let visibility = layer.get('visible');
  let layerSource = layer.get('serverName');
  let div = "";

  let legendService = "https://data.varnamap.bg//geoserver/varnamap/wms?Service=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=15&HEIGHT=20&LAYER="
  
  if (!layer.getLayers && layerSource != null) {
      
      let layerName = layer.get('name');
      let legendSource = legendService + layerSource;
      let layerVisible = layer.get('visible');
      
      if(layerVisible == false){
          div = "<div class='legendItem' legend-layerid='"+ layerName +"'>" +
                  "<span class='legendItemName'>" + layerName + "</span>" +
                  "<img class='' id='legendItem" + layerSource + "' src= '" + legendSource + "'>" +
                "</div>";
       }
       else{
          div = "<div class='legendItem active' legend-layerid='"+ layerName +"'>" +
              "<span class='legendItemName'>" + layerName + "</span>" +
              "<img class='' id='legendItem" + layerSource + "' src= '" + legendSource + "'>" +
              "</div>";
       }
      
                
      //if(layerVisible == false){$("[legend-layerid='"+ layerName +"']").hide();}
  }
  else{
      div = "";
  }

  if (layer.getLayers) {
      let sublayersElem = ''; 
      let layers = layer.getLayers().getArray(),
              len = layers.length;
      for (let i = len - 1; i >= 0; i--) {
          sublayersElem += buildLegend(layers[i]);
          
      }
      elem = div + sublayersElem;
  } else {
      elem = div;
  }
  return elem;

  
}

/**
* Initialize the legend from the map layers
* @returns {undefined}
*/
function initializeLegend() {
  let legendElem = buildLegend(map.getLayerGroup());
  $('#legendMain').append(legendElem);
}

// function switchOsmLayer(layername){
//   var layer = findBy(map.getLayerGroup(), 'name', layername);
// }

/**
 * Search tool - using WFS GetFeature from searchInput box to retrieve 
 * info from layer
 * 
 */
 function searchForFeatures(inputVal) {

  console.log(inputVal);
  if (inputVal !== ''){

      vectorSource.clear();
      vectorSourceSelected.clear();

      let searchValue = '%' + inputVal + '%';
      
      // generate a GetFeature request
      let featureRequest = new WFS().writeGetFeature({
        srsName: 'EPSG:32635',
        featureNS: 'varna',
        featurePrefix: 'varna',
        featureTypes: ['imoti_1'],
        outputFormat: 'application/json',
        filter: like('UPI', searchValue, '%')
      })
  
      fetchFeatures(featureRequest);

  }
}

map.on('click', function(evt) {

  vectorSource.clear();
  vectorSourceSelected.clear();

  let coords = evt.coordinate;
  let bbox_area = [coords[0] - 1,coords[1] - 1,coords[0] + 1,coords[1] + 1];
  let coordX = coords[0].toString().replace(/^(\d+\.\d{2}).*$/, '$1');
  let coordY = coords[1].toString().replace(/^(\d+\.\d{2}).*$/, '$1');

  let coordsForInput = coordX + ', ' + coordY;
  //document.getElementById('searchInput').value = coordsForInput;
  //a letiable to hold the features returned from the POST
  let features;

  // generate a GetFeature request
  let featureRequest = new WFS().writeGetFeature({
      srsName: 'EPSG:32635',
      featureNS: 'varna',
      featurePrefix: 'varna',
      featureTypes: ['imoti_1'],
      outputFormat: 'application/json',
      filter: new Bbox('the_geom', bbox_area)
  });
 
  fetchFeatures(featureRequest);

});

/**
 * Sends a XMLHttpRequest to the Geoserver and waits for 
 * success or error response
 * 
 * @param {type} featureRequest
 * 
 */
 function fetchFeatures(featureRequest){

  $('#resultsPanel').empty();
  $('div#resultsPanel').css('height', '');

  let body = new XMLSerializer().serializeToString(featureRequest);

  let httpRequest = new XMLHttpRequest();
  let requestUrl = "https://data.varnamap.bg//geoserver/wfs";
  httpRequest.open("POST", requestUrl, true);
  httpRequest.setRequestHeader("Content-type", "text/plain;charset=UTF-8");

  httpRequest.onreadystatechange = function() {//Call a function when the state changes.
    if(httpRequest.readyState == 4 && httpRequest.status == 200) {
      try{
          $('#alertWrapper').hide();
          vectorSourceSelected.clear();

          let features = new GeoJSON().readFeatures(httpRequest.responseText);
          vectorSource.addFeatures(features);
          zoomToExtent(vectorSource.getExtent());
          fillFeaturesInList(features);           
      }
      catch(e){

        $('.isLoadingIcon').hide();
        if (e.code == 25){
            $('#alertWrapper .alertMessage').text('Не са намерени резултати от търсенето!')
            $('#alertWrapper').show();
        } else {
            //$('#alertWrapper .alertMessage').text('Възникна проблем, моля опитайте отново!')
            //$('#alertWrapper').show();
            console.log(e);
        }
        
      }
    }
  }
  httpRequest.send(body);

}

/**
* Error handler for fetch request
* 
* @param {type} error
* 
*/
function handleFetchErrors(error){

  $('.isLoadingIcon').hide();

  if (error.code = 25){
      $('#alertWrapper .alertMessage').text('no result')
      $('#alertWrapper').show();
  }
  else {
      $('#alertWrapper .alertMessage').text('some other error')
      $('#alertWrapper').show();
  }
}

/**
* Loads the returned features from search/identification to a list of found features
* 
* @param {type} features
* 
*/

function focusSelectedResult(searchId, featureId){
  $('.searchResult .searchResultCollapse').removeClass('show');
  $('.searchResult #collapse'+ searchId).addClass('show');
  
  vectorSourceSelected.clear();
  vectorSourceSelected.addFeature(vectorSource.getFeatureById(featureId));  
  zoomToExtent(vectorSource.getFeatureById(featureId).getGeometry().getExtent());
}
window.focusSelectedResult = focusSelectedResult;

function fillFeaturesInList(features){

  //let featuresArray = features.getArray();
  let len = features.length;
  for (let i = len - 1; i >= 0; i--){
      
    let feature = features[i];

    let featureProperties = feature.getProperties();
    let featureId = feature.getId();
    let div = '';
    
    div = '<div class="card searchResult">' + 
            '<div class="card-header searchResultHeading" id="heading'+ i +'" onClick="focusSelectedResult(\''+ i +'\', \''+ featureId +'\')">' + 
              '<h5 class="mb-0 searchResultTitle">';
    
    //if (/^Kadastar_ot_platnata/.test(featureId)){
    //  div +=    '<i class="bi bi-geo-alt">Имоти: ' + featureProperties.UPI +'</i>';
    //} else if (/^KVS_joined/.test(featureId)){
      div +=    '<i class="bi bi-geo-alt">' + featureProperties.UPI +'</i>';
    // } else if (/^PUP_Imoti_poly/.test(featureId)){
    //   div +=    '<i class="bi bi-geo-alt">ПУП: ' + featureProperties.Nomer_UPI +'</i>';
    //}

    div +=  '</h5>' +
          '</div>' +

          '<div id="collapse'+ i +'" class="collapse searchResultCollapse" aria-labelledby="heading'+ i +'" data-parent="#resultsPanel">' +
            '<div class="card-body searchResultBody">' +
              '<ul>';
    div +=    '<li><span class="key"></span><span class="value">'+ featureProperties.UPI +'</span></li>';              
    if (/^imoti_1/.test(featureId)){
      let pupPlosht = featureProperties.Shape_Area.toString();
      pupPlosht = pupPlosht.substring(0, pupPlosht.indexOf('.')+3);

    //   div +=    featureProperties.Zemlishte ? '<li><span class="key">Землище: </span><span class="value">'+ featureProperties.Zemlishte +'</span></li>':'';
    //   div +=    '<li><span class="key">Площ: </span><span class="value">'+ pupPlosht + ' кв.м</span></li>';
    // } else if (/^Cadastre/.test(featureId)){

    //   let pupPlosht = featureProperties.Shape_Area.toString();
    //   pupPlosht = pupPlosht.substring(0, pupPlosht.indexOf('.')+3);

    //   div +=    featureProperties.MESTNOST_T ? '<li><span class="key">Местност: </span><span class="value">'+ featureProperties.MESTNOST_T +'</span></li>':'';
    //   div +=    featureProperties.VIDT_N14 ? '<li><span class="key">Вид територия: </span><span class="value">'+ featureProperties.VIDT_N14 +'</span></li>':'';
      div +=    featureProperties.EKATTE ? '<li><span class="key">EKATTE: </span><span class="value">'+ featureProperties.EKATTE +'</span></li>':'';
      // div +=    featureProperties.OLD_NUMBER ? '<li><span class="key">OLD_NUMBER: </span><span class="value">'+ featureProperties.OLD_NUMBER +'</span></li>':'';
      // div +=    featureProperties.PLACE_NAME ? '<li><span class="key">PLACE_NAME: </span><span class="value">'+ featureProperties.PLACE_NAME +'</span></li>':'';
      // div +=    featureProperties.USETYPE ? '<li><span class="key">USETYPE: </span><span class="value">'+ featureProperties.USETYPE +'</span></li>':'';
      // div +=    featureProperties.TERRTYPE ? '<li><span class="key">TERRTYPE: </span><span class="value">'+ featureProperties.TERRTYPE +'</span></li>':'';
      // div +=    featureProperties.LAW_TYPE ? '<li><span class="key">LAW_TYPE: </span><span class="value">'+ featureProperties.LAW_TYPE +'</span></li>':'';
      // div +=    featureProperties.PRAVA ? '<li><span class="key">PRAVA: </span><span class="value">'+ featureProperties.PRAVA +'</span></li>':'';
      // div +=    featureProperties.SUBEKT ? '<li><span class="key">SUBEKT: </span><span class="value">'+ featureProperties.SUBEKT +'</span></li>':'';
      // div +=    featureProperties.DOKUMENT ? '<li><span class="key">DOKUMENT: </span><span class="value">'+ featureProperties.DOKUMENT +'</span></li>':'';
      // div +=    featureProperties.IZDATEL ? '<li><span class="key">IZDATEL: </span><span class="value">'+ featureProperties.IZDATEL +'</span></li>':'';
      div +=    featureProperties.Kategoria1 ? '<li><span class="key">Категория: </span><span class="value">'+ featureProperties.Kategoria1 +'</span></li>':'';
      div +=    featureProperties.VID_ZONA ? '<li><span class="key">Вид зона: </span><span class="value">'+ featureProperties.VID_ZONA +'</span></li>':'';
      div +=    featureProperties.USTR_KAT ? '<li><span class="key">Устройствена зона: </span><span class="value">'+ featureProperties.USTR_KAT +'</span></li>':'';
      div +=    featureProperties.Prednaznac ? '<li><span class="key">Предназначение: </span><span class="value">'+ featureProperties.Prednaznac +'</span></li>':'';
      div +=    '<li><span class="key">Площ: </span><span class="value">'+ pupPlosht +' кв.м</span></li>';

    } else if (/^KVS_joined/.test(featureId)){

      let pupPlosht = featureProperties.Shape_Area.toString();
      pupPlosht = pupPlosht.substring(0, pupPlosht.indexOf('.')+3);

    //   div +=    featureProperties.Etajnost_o ? '<li><span class="key">Етажност: </span><span class="value">'+ featureProperties.Etajnost_o +'</span></li>':'';
    //   div +=    featureProperties.Platnost ? '<li><span class="key">Плътност: </span><span class="value">'+ featureProperties.Platnost +'</span></li>':'';
    //   div +=    featureProperties.Kint ? '<li><span class="key">Кинт: </span><span class="value">'+ featureProperties.Kint +'</span></li>':'';
    //   div +=    featureProperties.Ozelenqvan ? '<li><span class="key">Озеленяване: </span><span class="value">'+ featureProperties.Ozelenqvan +'</span></li>':'';
      div +=    featureProperties.sobstvenost ? '<li><span class="key">Вид собственост: </span><span class="value">'+ featureProperties.sobstvenost +'</span></li>':'';
      div +=    featureProperties.USETYPE_OLD ? '<li><span class="key">НТП: </span><span class="value">'+ featureProperties.USETYPE_OLD +'</span></li>':'';
    //   div +=    featureProperties.Zapoved ? '<li><span class="key">Заповед: </span><span class="value">'+ featureProperties.Zapoved +'</span></li>':'';
    //   div +=    featureProperties.Nomer_Imot ? '<li><span class="key">Номер имот: </span><span class="value">'+ featureProperties.Nomer_Imot +'</span></li>':'';
    //   div +=    featureProperties.ustanoveno ? '<li><span class="key">Устройствено отреждане: </span><span class="value">'+ featureProperties.ustanoveno +'</span></li>':'';
    //   div +=    featureProperties.Address ? '<li><span class="key">Адрес: </span><span class="value">'+ featureProperties.Address +'</span></li>':'';
      div +=    '<li><span class="key">Площ: </span><span class="value">'+ pupPlosht +' кв.м</span></li>';

    }              
    div +=    '</ul>' +
            '</div>' +
          '</div>' +
          '</div>';

    $('#resultsPanel').append(div);

  }

  $('.isLoadingIcon').hide();
  $('.clearIcon').show();

  let resultsPanelH = $('#resultsPanel').height();
  let windowH = $(window).height() - 100;
  if (resultsPanelH > windowH){
      //$('#resultsPanel').height($(window).height()-110);  
      $('div#resultsPanel').css('height', $(window).height()-110);
  } else {
      $('div#resultsPanel').css('height', '');
  }
  
  //$('.scrollerContainer').jScrollPane();

}

/**
* Clears vector layer and results
* 
*/
function clearRightPanel(){
  vectorSource.clear();
  vectorSourceSelected.clear();
  
  $('.clearIcon').hide();
  $('#resultsPanel').empty();
  $('#searchInput').val('');
  $('div#resultsPanel').css('height', '');
}
window.clearRightPanel = clearRightPanel;
/**
 * Zooms the map to the given extent 
 * 
 * @param {type} extent
 * 
 */
function zoomToExtent(featureExtent){
  map.getView().fit([featureExtent[0]-5,featureExtent[1]-5,featureExtent[2]+5,featureExtent[3]+5], {size: map.getSize(), maxZoom:18, duration: 1000 });
  //map.getView().animate({zoom: 15},{ center: getCenter([featureExtent[0]-5,featureExtent[1]-5,featureExtent[2]+5,featureExtent[3]+5]) }, { duration: 750 });
}

$(function() {
  
  initializeTree();
  initializeLegend();

  $("#searchInput").on("keyup",function(){
    $('.isLoadingIcon').show();
    delay(function(){
      searchForFeatures($('#searchInput').val());
    }, 500);
  });


  // Handle osm visibility control
  $('.btn-osm').on('click', function() {
    var layername = $(this).attr('id');
    var layerOsm = findBy(map.getLayerGroup(), 'name', 'osm');
    var layerOsmsat = findBy(map.getLayerGroup(), 'name', 'osmsat');

    if (layername == 'osm'){
      layerOsm.setVisible(true);
      layerOsmsat.setVisible(false);
      $('#osmsat').removeClass('hidden');
      $('#osm').addClass('hidden');
    } else if (layername == 'osmsat'){
      layerOsm.setVisible(false);
      layerOsmsat.setVisible(true);
      $('#osmsat').addClass('hidden');
      $('#osm').removeClass('hidden');
    }
  });

  // Handle visibility control
  $('i').on('click', function() {
    var layername = $(this).closest('li').data('layerid');
    var layer = findBy(map.getLayerGroup(), 'name', layername);
    
    if (!layer.getLayers) {
      layer.setVisible(!layer.getVisible());

      if (layer.getVisible()) {
          $(this).removeClass('bi-toggle-off').addClass('bi-toggle-on');
          //shows legend of selected item
          //$("[legend-layerid='"+ layername +"']").show();
          $("[legend-layerid='"+ layername +"']").addClass("active");

      } else {
          $(this).removeClass('bi-toggle-on').addClass('bi-toggle-off');
          //shows legend of selected item
          //$("[legend-layerid='"+ layername +"']").hide();
          $("[legend-layerid='"+ layername +"']").removeClass("active");
      }
    }
  });

  $('i.bi-list').on('click', function() {
    initializeTree();
    initializeLegend();
    const leftPanelState = $( "#leftPanel" ).hasClass( "active" );
    console.log(leftPanelState);
    leftPanelState ? $( "#leftPanel" ).removeClass( "active" ) : $( "#leftPanel" ).addClass( "active" );
  });
});